import { useEffect } from "react";

import AuthService from "../../../services/AuthService";

export default function Logout(props) {
  useEffect(() => {
    AuthService.logout();
    props.history.push("/admin/login");
  }, [props.history]);

  return null;
}
