import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  paper: {
    position: "unset"
  },
  content: {
    minHeight: 100,
    minWidth: 110,
    paddingLeft: 24,
    paddingRight: 24
  },
  headerContainer: {},
  iconContainer: {
    position: "absolute",
    padding: 15,
    marginTop: -20,
    borderRadius: 3,
    background: "linear-gradient(60deg, #ec407a, #d81b60)",
    boxShadow:
      "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(233, 30, 99,.4)"
  },
  icon: {
    height: 24,
    width: 24,
    color: "#fff",
    margin: "5px 4px 0px"
  },
  titleContainer: {
    marginLeft: 85,
    paddingTop: 22
  },
  main: {
    marginTop: 30
  }
}));

const CustomDialog = props => {
  const classes = useStyles();
  return (
    <Dialog
      {...props}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      classes={{
        paper: classes.paper
      }}
      fullWidth={props.fullWidth}
    >
      <div className={classes.content}>
        <div className={classes.headerContainer}>
          <div className={classes.iconContainer}>
            <props.icon className={classes.icon} />
          </div>
          <div className={classes.titleContainer}>
            <Typography variant="body1">{props.title}</Typography>
          </div>
          <div className={classes.main}>{props.main}</div>
        </div>
      </div>
    </Dialog>
  );
};

export default CustomDialog;

CustomDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  main: PropTypes.node,
  className: PropTypes.any,
  fullWidth: PropTypes.bool
};
