import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import { Form, Field } from "react-final-form";

import FormValidators from '../helpers/form-validators';
import CustomNumberFormat from './CustomNumberFormat';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewConnectionDialog({ open, handleClose, handleSubmit }) {
    return (
        <Dialog
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <Form
                onSubmit={handleSubmit}
                initialValues={{
                    name: "",
                    email: "",
                    message: "",
                }}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle id="form-dialog-title">Замовлення інтернет підключення</DialogTitle>
                        <DialogContent>
                            <Field
                                name="name"
                                validate={
                                    FormValidators.composeValidators(
                                        FormValidators.required(),
                                    )}
                            >
                                {props => {
                                    const {
                                        input: {
                                            name,
                                            onChange,
                                            value,
                                            ...restInput
                                        },
                                        meta,
                                    } = props;
                                    return (
                                        <TextField
                                            {...restInput}
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            fullWidth
                                            label="Ваше ім'я"
                                            variant="outlined"
                                            helperText={meta.touched ? meta.error || meta.submitError : undefined}
                                            error={(meta.error || meta.submitError) && meta.touched}
                                            style={{ marginBottom: 20, marginTop: 20 }}
                                        />
                                    )
                                }}
                            </Field>
                            <Field
                                name="number"
                                validate={
                                    FormValidators.composeValidators(
                                        FormValidators.required(),
                                        FormValidators.isPhoneNumber(),
                                    )}
                            >
                                {props => {
                                    const {
                                        input: {
                                            name,
                                            onChange,
                                            value,
                                            ...restInput
                                        },
                                        meta,
                                    } = props;
                                    return (
                                        <TextField
                                            InputProps={{
                                                inputComponent: CustomNumberFormat,
                                            }}
                                            {...restInput}
                                            name={name}
                                            onChange={onChange}
                                            value={value}
                                            fullWidth
                                            label="Ваш номер телефону"
                                            variant="outlined"
                                            helperText={meta.touched ? meta.error || meta.submitError : undefined}
                                            error={(meta.error || meta.submitError) && meta.touched}
                                            style={{ marginBottom: 20, marginTop: 20 }}
                                        />
                                    )
                                }}
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                {"Відміна"}
                            </Button>
                            <Button color="secondary" variant="contained" type="submit">
                                {"Замовити"}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    );
}