import React from "react";
import PropTypes from "prop-types";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import DeleteIcon from "@material-ui/icons/Delete";

// react-final-form
import { Form } from "react-final-form";

// core components
import Dialog from "../components/Dialog";

import Button from "../components/CustomButtons/Button";
import { EntityTypes } from ".";
import { DialogContentText } from "@material-ui/core";

export default function ConfirmationDialog(props) {
  const { open, toggleDialog, initialValues, type, onSubmit } = props;
  const title =
    type === EntityTypes.location
      ? "регіону"
      : type === EntityTypes.plan
      ? "плану"
      : "диллера";
  const message =
    type === EntityTypes.location
      ? "Ви впененні що хочете видалити регіон? Ви також видалите всі плани та диллерів (якщо регіон їх містив)."
      : type === EntityTypes.plan
      ? "Ви впененні що хочете видалити план?"
      : "Ви впененні що хочете видалити диллера?";
  return (
    <Dialog
      open={open}
      onClose={() => toggleDialog(false)}
      icon={DeleteIcon}
      title={`Видалення ${title}`}
      main={
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ overflowY: "unset" }}>
                <DialogContentText id="alert-dialog-description">
                  {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => toggleDialog(false)} color="default">
                  {"Відміна"}
                </Button>
                <Button type="submit" color="rose">
                  {"Видалити"}
                </Button>
              </DialogActions>
            </form>
          )}
        />
      }
    ></Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  toggleDialog: PropTypes.func,
  initialValues: PropTypes.any,
  onSubmit: PropTypes.func,
  type: PropTypes.string
};
