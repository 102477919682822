import * as React from 'react';
import { NavLink } from "react-router-dom";

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';


import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Menu from "@material-ui/icons/Menu";
import PlansIcon from "@material-ui/icons/MonetizationOn";
import DealersIcon from "@material-ui/icons/SupervisedUserCircle";
import LogoutIcon from "@material-ui/icons/ExitToApp";

const Sidebar = (props) => {
    const [open, setOpen] = React.useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <SwipeableDrawer
                open={open}
                anchor={matches ? "top" : "left"}
                onClose={() => setOpen(false)}
                onOpen={() => { }}
                closeAfterTransition
            >
                <List>
                    <NavLink to="/admin/locationPlans">
                        <ListItem button style={{ color: "grey" }}>
                            <ListItemIcon>
                                <PlansIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Регіони та плани"} />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/admin/dealers">
                        <ListItem button style={{ color: "grey" }}>
                            <ListItemIcon>
                                <DealersIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Диллери"} />
                        </ListItem>
                    </NavLink>
                    <Divider />
                    <NavLink to="/admin/logout">
                        <ListItem button style={{ color: "grey" }}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Вихід" />
                        </ListItem>
                    </NavLink>
                </List>
            </SwipeableDrawer>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(true)}
            >
                <Menu />
            </IconButton>
        </>
    );
};

export default Sidebar;

// const useStyles = makeStyles({
//     list: {
//         width: 250,
//     },
//     fullList: {
//         width: 'auto',
//     },
// });

// export default function SwipeableTemporaryDrawer() {
//     const classes = useStyles();
//     const [state, setState] = React.useState({
//         top: false,
//         left: false,
//         bottom: false,
//         right: false,
//     });

//     const toggleDrawer = (anchor, open) => (event) => {
//         if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//             return;
//         }

//         setState({ ...state, [anchor]: open });
//     };

//     const list = (anchor) => (
//         <div
//             className={clsx(classes.list, {
//                 [classes.fullList]: anchor === 'top' || anchor === 'bottom',
//             })}
//             role="presentation"
//             onClick={toggleDrawer(anchor, false)}
//             onKeyDown={toggleDrawer(anchor, false)}
//         >
//             <List>
//                 {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
//                     <ListItem button key={text}>
//                         <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
//                         <ListItemText primary={text} />
//                     </ListItem>
//                 ))}
//             </List>
//             <Divider />
//             <List>
//                 {['All mail', 'Trash', 'Spam'].map((text, index) => (
//                     <ListItem button key={text}>
//                         <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
//                         <ListItemText primary={text} />
//                     </ListItem>
//                 ))}
//             </List>
//         </div>
//     );

//     return (
//         <div>
//             {['left', 'right', 'top', 'bottom'].map((anchor) => (
//                 <React.Fragment key={anchor}>
//                     <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
//                     <SwipeableDrawer
//                         anchor={anchor}
//                         open={state[anchor]}
//                         onClose={toggleDrawer(anchor, false)}
//                         onOpen={toggleDrawer(anchor, true)}
//                     >
//                         {list(anchor)}
//                     </SwipeableDrawer>
//                 </React.Fragment>
//             ))}
//         </div>
//     );
// }