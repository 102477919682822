/* eslint-disable react/prop-types */
import * as React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";
import styles from "../../assets/jss/material-dashboard-react/components/customInputStyle";

const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;
  const format = "+# (###) ###-#####";
  return (
    <NumberFormat
      {...other}
      format={format}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const TextFieldControl = props => {
  const {
    classes,
    label,
    multiline,
    rowsMax,
    rows,
    input: { name, onChange, type, value, ...restInput },
    meta,
    min,
    variant,
    InputProps,
    withPhoneMask,
    ...rest
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]:
      (meta.error || meta.submitError) && meta.touched
  });
  const underlineClasses = classNames({
    [classes.underlineError]: (meta.error || meta.submitError) && meta.touched,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: label === undefined
  });

  if (withPhoneMask) {
    return (
      <TextField
        className={classes.root}
        {...props}
        fullWidth={true}
        helperText={meta.touched ? meta.error || meta.submitError : undefined}
        error={(meta.error || meta.submitError) && meta.touched}
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom,
          classes: {
            root: marginTop,
            underline: underlineClasses
          },
          ...props.InputProps
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.labelRoot + labelClasses
        }}
        onChange={onChange}
        value={value}
      />
    );
  }
  // `InputLabelProps` required since `restInput` override `onFocus` action
  return (
    <TextField
      {...rest}
      classes={{
        root: classes.root
      }}
      type={type}
      fullWidth={true}
      label={label}
      value={value}
      name={name}
      helperText={meta.touched ? meta.error || meta.submitError : undefined}
      error={(meta.error || meta.submitError) && meta.touched}
      InputProps={{
        ...InputProps,
        inputProps: min ? { min } : {},
        classes: {
          root: marginTop,
          underline: underlineClasses
        }
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.labelRoot + labelClasses
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        ...restInput,
        "data-cy": name
      }}
      onChange={onChange}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      margin="dense"
      variant={variant}
    />
  );
};

export default withStyles(styles)(TextFieldControl);
