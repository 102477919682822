import React, { } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// icons
import NetworkCheck from "@material-ui/icons/NetworkCheck";
import Money from "@material-ui/icons/Money";
import ContactPhone from "@material-ui/icons/ContactPhone";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F2F5F8",
        paddingTop: 50,
    },
    header: {
        fontWeight: 500,
        marginBottom: theme.spacing(5),
    },
    benefitsList: {
        marginTop: theme.spacing(10),
        justifyContent: "center",
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
            marginTop: theme.spacing(4),
        },
    },
    benefit: {
        margin: 10,
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "row",
            alignItems: "flex-start",
        },
    },
    benefitIcon: {
        [theme.breakpoints.down("sm")]: {
            marginRight: 10,
        },
        "& svg": {
            fontSize: 70,
            [theme.breakpoints.down("sm")]: {
                fontSize: 30,
            },
        },
    },
    title: {
        marginBottom: theme.spacing(3),
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
            textAlign: "left",
            marginBottom: theme.spacing(2)
        },
    },
    content: {
        [theme.breakpoints.down("sm")]: {
            textAlign: "left",
        },
    },
}));


const benefits = [
    {
        icon: <NetworkCheck />,
        title: "Висока швидкість",
        content: `Можливість доступу до інформаційних ресурсів Інтернет 
        на максимально можливій швидкості`,
    },
    {
        icon: <Money />,
        title: "Оптимальна ціна",
        content: `Вигідна цінова пропозиція на високошвидкісний доступ 
        до мережі Інтернет`,
    },
    {
        icon: <ContactPhone />,
        title: "Підтримка",
        content: `Оперативний виїзд фахівців для перевірки технічної 
        можливості підключення (за потреби)`,
    },
];

const Benefits = () => {
    const classes = useStyles();
    return (
        <Container id="benefits" maxWidth="xl" className={classes.root}>
            <Typography component="p" variant="h4" align="center" color="textPrimary" gutterBottom>
                {"Наші переваги"}
            </Typography>
            <Grid container className={classes.benefitsList}>
                {benefits.map(benefit => (
                    <Grid key={benefit.title} item xs={10} sm={3} className={classes.benefit}>
                        <div className={classes.benefitIcon}>{benefit.icon}</div>
                        <Box>
                            <Typography variant="h6" className={classes.title} align="center">{benefit.title}</Typography>
                            <Typography
                                variant="body1"
                                className={classes.content}
                                gutterBottom
                                align="center"
                                color="textPrimary"
                            >
                                {benefit.content}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Benefits;