const locations = {
  get: "/api/location/get",
  add: "/api/location/post",
  update: "/api/location/put",
  delete: "/api/location/delete",
  updateDealers: "/api/location/updateDealers",
};

const plans = {
  add: "/api/plan/post",
  update: "/api/plan/put",
  setFavorite: "/api/plan/setFavorite",
  delete: "/api/plan/delete"
};

const dealers = {
  get: "/api/dealer/get",
  add: "/api/dealer/post",
  update: "/api/dealer/put",
  delete: "/api/dealer/delete"
};

const connection = {
  get: "/api/connection/get",
  add: "/api/connection/post",
  contactUs: "/api/connection/contactUs",
};

const user = {
  login: "/api/user/login",
  logout: "/api/user/logout"
};

export default {
  user,
  locations,
  plans,
  dealers,
  connection
};
