import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import DealersIcon from "@material-ui/icons/SupervisedUserCircle";

// core components
import Dialog from "../components/Dialog";
import Button from "../components/CustomButtons/Button";
import CustomSelect from "../components/CustomSelect/CustomSelect";
import CustomMenuItem from "../components/CustomMenuItem/CustomMenuItem";

export default function DealersDialog(props) {
  const { open, toggleDialog, initialValues, options, onSubmit } = props;
  const [value, setValue] = useState([]);

  const handleChange = value => {
    setValue(value);
  };

  useEffect(() => {
    if (initialValues) {
      setValue(initialValues.dealers);
    }
  }, [initialValues]);

  const submit = () => {
    let dealers = [];
    value.forEach(id => {
      const dealer = options.find(x => x._id === id);
      if (dealer) {
        dealers.push(dealer);
      }
    });
    const values = {
      locationId: initialValues.locationId,
      dealers,
    };
    onSubmit(values);
  };

  return (
    <Dialog
      open={open}
      onClose={() => toggleDialog(false)}
      icon={DealersIcon}
      title={"Диллери"}
      fullWidth
      main={
        <>
          <DialogContent style={{ overflowY: "unset" }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <CustomSelect
                  multiple
                  onChange={e => handleChange(e.target.value)}
                  value={value}
                >
                  {options.map(option => (
                    <CustomMenuItem key={option.name} value={option._id}>
                      {option.name}
                    </CustomMenuItem>
                  ))}
                </CustomSelect>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => toggleDialog(false)} color="default">
              {"Відміна"}
            </Button>
            <Button onClick={submit} color="rose">
              {"Зберегти"}
            </Button>
          </DialogActions>
        </>
      }
    />
  );
}

DealersDialog.propTypes = {
  open: PropTypes.bool,
  toggleDialog: PropTypes.func,
  initialValues: PropTypes.any,
  options: PropTypes.array,
  onSubmit: PropTypes.func,
  value: PropTypes.any,
};
