import * as EmailValidator from "email-validator";

export default class FormValidators {
  static required = (message = "") => value => {
    let returnMessage = "Обов'язкове поле для заповення";
    if (message !== "") {
      returnMessage = message;
    }
    return value ? undefined : returnMessage;
  };

  static isEmail(value) {
    return EmailValidator.validate(value)
      ? undefined
      : "Введіть валідний Email";
  }

  static isPhoneNumber = (message = "") => value => {
    let returnMessage = "Неправильний номер телефону";
    if (message !== "") {
      returnMessage = message;
    }
    return value.length > 11 ? undefined : returnMessage;
  };

  static isURL(value) {
    if (!value) {
      return undefined;
    }
    // eslint-disable-next-line no-useless-escape
    let regex = /^((http(s)?|ftp)(:\/\/))?([www])?\.?[a-zA-Z0-9-_\.]+(\.[a-zA-Z0-9]{2,})([-a-zA-Z0-9:%_\+.~#?&//=]*)/gi;
    return regex.test(String(value)) ? undefined : "Не валідна адреса";
  }

  static isNumber(value) {
    if (!value) {
      return undefined;
    }
    value = value.replace(",", ".");
    // eslint-disable-next-line no-useless-escape
    let regex = /^-?\d+\.?\d*$/;
    return regex.test(String(value))
      ? undefined
      : "Поле може містити тільки цифри і точку";
  }

  static minValue = (length, message = "") => value => {
    let returnMessage = `Поле має мати не менше ${length} символів`;
    if (message !== "") {
      returnMessage = message;
    }

    if (value === undefined) {
      return undefined;
    } else {
      return value.length < length ? returnMessage : undefined;
    }
  };

  static composeValidators = (...validators) => value =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
}
