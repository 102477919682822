import React, { } from 'react';
import { Form, Field } from "react-final-form";

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";

import BackgroundImage from "../../assets/form-background.svg"
import FormValidators from '../../helpers/form-validators';
import CustomNumberFormat from '../../components/CustomNumberFormat';
import ConnectionService from '../../services/ConnectionService';
import { useSnackbar } from '../../components/Notification';

//@material-ui/icons
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "#fff",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(11),
            flexDirection: "column-reverse",
        },
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        borderRadius: 6,
        padding: theme.spacing(5),
        [theme.breakpoints.down("xs")]: {
            width: "95%",
            margin: theme.spacing(4),
            padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
        },
    },
    infoContainer: {
        margin: "auto",
        [theme.breakpoints.down("xs")]: {
            margin: "32px auto",
        },
    },
    white: {
        color: "#fff",
    },
}));

const ContactUs = () => {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const onSubmit = async (values, form) => {
        try {
            const res = await ConnectionService.ContactUs(values);
            if (res.data.message) {
                snackbar.showMessage(res.data.message);
            }
            setTimeout(() => {
                Object.keys(values).forEach(key => {
                    form.resetFieldState(key);
                });
                form.reset();
            });
        } catch (err) {
            console.error(err.message);
        }
    };

    return (
        <div className={classes.root} id="contact-us">
            <Box className={classes.infoContainer}>
                <Box>
                    <Typography variant="h5" style={{ color: "#fff" }}>Контактні телефони</Typography>
                    <Box style={{ height: 12 }}></Box>
                    <Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography className={classes.white}>+380 (96) 220 32 70 (Kyivstar)</Typography>
                            <IconButton
                                href="tel:+380 (96) 220 32 70"
                                className={classes.phoneIconButton}
                                size="small"
                            >
                                <PhoneIcon color="secondary" className={classes.icon} />
                            </IconButton>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography className={classes.white}>+380 (95) 220 32 70 (Vodafone)</Typography>
                            <IconButton
                                href="tel:+380 (95) 220 32 70"
                                className={classes.phoneIconButton}
                                size="small"
                            >
                                <PhoneIcon color="secondary" className={classes.icon} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Box height={24} />
                <Box>
                    <Typography variant="h5" style={{ color: "#fff" }}>Адреса</Typography>
                    <Box style={{ height: 12 }}></Box>
                    <Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography className={classes.white}>м. Чернівці, вул. Головна 256</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography className={classes.white}>пн-пт 09:00 - 18:00</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography className={classes.white}>пн 12:00 - 18:00</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Container maxWidth="sm" component="main" className={classes.formContainer}>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{
                        name: "",
                        phone: "",
                        email: "",
                        message: "",
                    }}
                    render={({ handleSubmit, form: { reset } }) => {
                        return (
                            <form
                                onSubmit={handleSubmit}
                            >
                                <Typography variant="inherit" component="h2" align="center" gutterBottom>
                                    {"Зворотній зв'язок"}
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Field name="name" validate={FormValidators.required()}>
                                            {props => {
                                                const {
                                                    input: { name, onChange, value, ...restInput },
                                                    meta,
                                                } = props;
                                                return (
                                                    <TextField
                                                        {...restInput}
                                                        name={name}
                                                        onChange={onChange}
                                                        value={value}
                                                        fullWidth
                                                        label="Ім'я *"
                                                        variant="outlined"
                                                        size="small"
                                                        helperText={meta.touched ? meta.error || meta.submitError : undefined}
                                                        error={(meta.error || meta.submitError) && meta.touched}
                                                        style={{ marginBottom: 20, marginTop: 20 }}
                                                    />
                                                );
                                            }}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field name="phone" validate={FormValidators.composeValidators(
                                            FormValidators.required(),
                                            FormValidators.isPhoneNumber(),
                                        )}>
                                            {props => {
                                                const {
                                                    input: { name, onChange, value, ...restInput },
                                                    meta,
                                                } = props;
                                                return (
                                                    <TextField
                                                        {...restInput}
                                                        InputProps={{
                                                            inputComponent: CustomNumberFormat,
                                                        }}
                                                        name={name}
                                                        onChange={onChange}
                                                        value={value}
                                                        fullWidth
                                                        label="Номер телефону *"
                                                        variant="outlined"
                                                        size="small"
                                                        helperText={meta.touched ? meta.error || meta.submitError : undefined}
                                                        error={(meta.error || meta.submitError) && meta.touched}
                                                        style={{ marginBottom: 20, marginTop: 20 }}
                                                    />
                                                );
                                            }}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field name="email">
                                            {props => {
                                                const {
                                                    input: { name, onChange, value, ...restInput },
                                                    meta,
                                                } = props;
                                                return (
                                                    <TextField
                                                        {...restInput}
                                                        name={name}
                                                        onChange={onChange}
                                                        value={value}
                                                        fullWidth
                                                        label="Email"
                                                        variant="outlined"
                                                        size="small"
                                                        helperText={meta.touched ? meta.error || meta.submitError : undefined}
                                                        error={(meta.error || meta.submitError) && meta.touched}
                                                        style={{ marginBottom: 20, marginTop: 20 }}
                                                    />
                                                );
                                            }}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field name="message" validate={FormValidators.required()}>
                                            {props => {
                                                const {
                                                    input: { name, onChange, value, ...restInput },
                                                    meta,
                                                } = props;
                                                return (
                                                    <TextField
                                                        {...restInput}
                                                        name={name}
                                                        onChange={onChange}
                                                        value={value}
                                                        fullWidth
                                                        label="Повідомлення *"
                                                        variant="outlined"
                                                        size="small"
                                                        multiline
                                                        rows={5}
                                                        helperText={meta.touched ? meta.error || meta.submitError : undefined}
                                                        error={(meta.error || meta.submitError) && meta.touched}
                                                        style={{ marginBottom: 20 }}
                                                    />
                                                );
                                            }}
                                        </Field>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5} justify="flex-end">
                                    <Grid item>
                                        <Button type="submit" color="primary" variant="contained">
                                            {"Надіслати"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )
                    }}
                />
            </Container>
        </div >
    );
};

export default ContactUs;