import React from "react";

import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
// import Drawer from './Drawer';

import { makeStyles } from "@material-ui/core/styles";

import LogoWhite from "../assets/logo-white.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    backgroundColor: "transparent",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4),
    },
  },
  toolbar: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  brandLogoContainer: {
    flex: 1,
  },
  brandLogo: {
    flexGrow: 1,
    height: 50,
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
  },
  navItems: {
    "& a": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      fontWeight: 600,
    },
  },
}));

export default function Appbar() {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.brandLogoContainer}>
            <img src={LogoWhite} className={classes.brandLogo} alt="brand" />
          </div>
          <Hidden xsDown>
            <div className={classes.navItems}>
              <Button color="inherit" href="#benefits">
                Переваги
              </Button>
              <Button color="inherit" href="#payments">
                Оплата
              </Button>
              <Button color="inherit" href="#contact-us">
                Контакти
              </Button>
              <Button
                color="primary"
                variant="contained"
                href="https://billing.opanky.net"
                target="_blank"
              >
                {"Кабінет"}
              </Button>
            </div>
          </Hidden>
          <Hidden smUp>
            {/* <Drawer /> */}
            <Button
              color="primary"
              variant="contained"
              href="https://billing.opanky.net"
              target="_blank"
              size="small"
            >
              {"Кабінет"}
            </Button>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Container>
  );
}
