import React, { } from 'react';
import classnames from "classnames";

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';

import AppBar from "../../components/AppBar";

import BackgroundImage from "../../assets/background2.svg";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#F2F5F8",
        display: "flex",
        alignItems: "flex-start",
    },
    rootLoaded: {
        minHeight: 500,
    },
    container: {
        display: "flex",
        flexDirection: "column",
    },
    header: {
        color: "#fff",
        fontWeight: 500,
        marginBottom: theme.spacing(4),
        fontSize: 52,
        [theme.breakpoints.down("sm")]: {
            fontSize: 40,
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 32,
        },
    },
    subheader: {
        color: "#fff",
        fontSize: 40,
        [theme.breakpoints.down("sm")]: {
            fontSize: 32,
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: 20,
        },
    },
    button: {
        margin: "45px auto 00px auto",
    },
}));

const Hero = ({ loading }) => {
    const classes = useStyles();
    return (
        <div className={classnames(classes.root, loading ? "" : classes.rootLoaded)}>
            <Container maxWidth="xl" component="main" className={classes.container}>
                <AppBar />
                <Grid container alignItems="center" justify="center">
                    <Grid item md={8} xs={12}>
                        <Typography component="p" variant="h2" align="center" gutterBottom className={classes.header}>
                            {"Інтернет там, де він потрібен"}
                        </Typography>
                    </Grid>
                    <Typography variant="h5" align="center" color="textSecondary" component="p" gutterBottom className={classes.subheader}>
                        {"Переходь на новий рівень швидкості та надійності"}
                    </Typography>
                </Grid>
                {loading ?
                    (
                        <CircularProgress size={44} className={classes.button} />
                    ) :
                    (
                        <Fade in>
                            <Button href="#tiers" className={classes.button} color="secondary" variant="contained">Підключитись</Button>
                        </Fade>
                    )}
            </Container>
        </div >
    );
};

export default Hero;