import React, { PureComponent } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import MuiAlert from '@material-ui/lab/Alert';

import SnackbarContext from './SnackbarContext'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function DefaultSnackbar({
    message, action, ButtonProps, SnackbarProps, customParameters
}) {
    return (
        <Snackbar
            {...SnackbarProps}
            message={message || ''}
            action={action != null && (
                <Button
                    color='secondary'
                    size='small'
                    {...ButtonProps}
                >
                    {action}
                </Button>
            )}
        >
            <Alert onClose={ButtonProps.onClick} severity="success">
                {message || ''}
            </Alert>
        </Snackbar>
    )
}

export default class SnackbarProvider extends PureComponent {
    state = {
        message: null,
        open: false
    }

    constructor(props) {
        super(props)
        this.contextValue = {
            showMessage: this.showMessage
        }
    }

    /**
     * Display a message with this snackbar.
     * @param {string} message message to display
     * @param {string} [action] label for the action button
     * @param {function} [handleAction] click handler for the action button
     * @param {any} [customParameters] custom parameters that will be passed to the snackbar renderer
     * @public
     */
    showMessage = (message, action, handleAction, customParameters) => {
        this.setState({ open: true, message, action, handleAction, customParameters })
    }

    handleActionClick = () => {
        this.handleClose();
        if (this.state.handleAction) {
            this.state.handleAction();
        }
    }

    handleClose = () => {
        this.setState({ open: false, handleAction: null })
    }

    render() {
        const {
            action,
            message,
            open,
            customParameters
        } = this.state

        const {
            ButtonProps = {},
            children,
            SnackbarProps = {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
            },
            SnackbarComponent = DefaultSnackbar
        } = this.props

        return (
            <>
                <SnackbarContext.Provider
                    value={this.contextValue}
                >
                    {children}
                </SnackbarContext.Provider>
                <SnackbarComponent
                    message={message}
                    action={action}
                    ButtonProps={{ ...ButtonProps, onClick: this.handleActionClick }}
                    SnackbarProps={{ ...SnackbarProps, open, onClose: this.handleClose }}
                    customParameters={customParameters}
                />
            </>
        )
    }
}