import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/customInputStyle";

const useStyles = makeStyles(styles);

export default function CustomMenuItem(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    <MenuItem
      {...props}
      classes={{
        root: classes.root,
        selected: classes.selected
      }}
    >
      {children}
    </MenuItem>
  );
}

CustomMenuItem.propTypes = {
  children: PropTypes.any
};
