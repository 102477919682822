import AxiosFactory from "./AxiosFactory";
import ApiPath from "../constants/ApiPath";
export default class LocationService {
  static Get() {
    return new AxiosFactory().axios({
      method: "GET",
      url: ApiPath.locations.get
    });
  }

  static GetById(id) {
    return new AxiosFactory().axios({
      method: "GET",
      url: `${ApiPath.locations.get}/${id}`
    });
  }

  static Add(data) {
    return new AxiosFactory(true).axios({
      method: "POST",
      url: `${ApiPath.locations.add}`,
      data
    });
  }

  static Update(data) {
    return new AxiosFactory(true).axios({
      method: "PUT",
      url: `${ApiPath.locations.update}/${data._id}`,
      data
    });
  }

  static Delete(id) {
    return new AxiosFactory(true).axios({
      method: "DELETE",
      url: `${ApiPath.locations.delete}/${id}`
    });
  }

  static UpdateDealers(id, data) {
    return new AxiosFactory(true).axios({
      method: "PUT",
      url: `${ApiPath.locations.updateDealers}/${id}`,
      data
    });
  }
}
