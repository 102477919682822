import AxiosFactory from "./AxiosFactory";
import ApiPath from "../constants/ApiPath";

export default class ConnectionService {
  static Get() {
    return new AxiosFactory(true).axios({
      method: "GET",
      url: ApiPath.connection.get,
    });
  }
  static Add(data) {
    return new AxiosFactory().axios({
      method: "POST",
      url: `${ApiPath.connection.add}`,
      data
    });
  }
  static ContactUs(data) {
    return new AxiosFactory().axios({
      method: "POST",
      url: `${ApiPath.connection.contactUs}`,
      data
    });
  }
}
