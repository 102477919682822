/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { Fade, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Table from "../components/Table/Table.js";
import Muted from "../components/Typography/Muted";

//@material-ui/icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

//services
import DealerService from "../../../services/DealerService";
import DealerDialog from "./DealerDialog";

import NumberFormat from "react-number-format";
import ConfirmationDialog from "./ConfirmationDialog";

const styles = {
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Comforta", sans-serif',
    marginBottom: "3px",
    textDecoration: "none"
  },
  numberInputCell: {
    border: 0,
    fontSize: "0.8125rem"
  }
};

const useStyles = makeStyles(styles);

export default function Dealers() {
  const classes = useStyles();
  const [fade, setFade] = useState(false);
  const [data, setData] = useState([]);
  const [dealerDialogOpen, setDealerDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const result = await DealerService.Get();
      if (result.data.length) {
        setData(result.data);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setFade(true);
    }
  };

  const onEditDealerClick = dealer => {
    setInitialValues({
      _id: dealer._id,
      name: dealer.name,
      phoneNumber: dealer.phoneNumber,
      email: dealer.email,
    });
    setDealerDialogOpen(true);
  };

  const onAddDealerClick = () => {
    setDealerDialogOpen(true);
    setInitialValues(null);
  };

  const onDeleteDealerClick = _id => {
    setInitialValues({
      _id
    });
    setConfirmationDialogOpen(true);
  };

  const onDeleteDealerConfirm = async values => {
    try {
      await DealerService.Delete(values._id);
      const index = data.findIndex(x => x._id === values._id);
      if (index !== -1) {
        const filteredData = data.filter(x => x._id !== values._id);
        setData(filteredData);
      }
      setConfirmationDialogOpen(false);
      setInitialValues(null);
    } catch (err) {
      console.log(err.message);
    }
  };

  const onSubmitDealerClick = async values => {
    try {
      const result = values._id
        ? await DealerService.Update(values)
        : await DealerService.Add(values);
      if (!values._id) {
        setData([...data, result.data]);
      } else {
        const index = data.findIndex(x => x._id === values._id);
        if (index !== -1) {
          const dataCopy = [...data];
          dataCopy[index].name = values.name;
          dataCopy[index].phoneNumber = values.phoneNumber;
          dataCopy[index].email = values.email;
          setData(dataCopy);
        }
      }
      setDealerDialogOpen(false);
    } catch (err) {
      if (err && err.response && err.response.data) {
        return err.response.data;
      }
    }
  };

  return (
    <Fade in={fade}>
      <GridContainer justify="center">
        <GridItem xs={12} md={8}>
          <Card>
            <CardHeader color="success">
              <div className={classes.headerContainer}>
                <h4 className={classes.cardTitleWhite}>Диллери</h4>
                <IconButton aria-label="Додати" onClick={onAddDealerClick}>
                  <AddIcon style={{ color: "#fff" }} />
                </IconButton>
              </div>
            </CardHeader>
            <CardBody>
              <div style={{ marginTop: 27 }}>
                <GridContainer justify="center">
                  {data.length > 0 && (
                    <Table
                      tableHeaderColor="success"
                      tableHead={["Ім'я", "Номер телефону", "Email", ""]}
                      tableData={data.map((dealer, index) => {
                        return [
                          dealer.name,
                          <NumberFormat
                            disabled
                            key={index}
                            type="text"
                            format="+# (###) ###-#####"
                            value={dealer.phoneNumber}
                            className={classes.numberInputCell}
                          />,
                          dealer.email,
                          <GridContainer key={index} justify="flex-end">
                            <GridItem>
                              <IconButton
                                onClick={() => onEditDealerClick(dealer)}
                                aria-label="Редагувати"
                              >
                                <EditIcon />
                              </IconButton>
                            </GridItem>
                            <GridItem>
                              <IconButton
                                onClick={() => onDeleteDealerClick(dealer._id)}
                                aria-label="Видалити"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </GridItem>
                          </GridContainer>
                        ];
                      })}
                    />
                  )}
                  {data.length === 0 && (
                    <Muted style={{ textAlign: "center", marginTop: 10, marginBottom: 20, }}>
                      {"Немає жодного диллера"}
                    </Muted>
                  )}
                </GridContainer>
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <DealerDialog
          open={dealerDialogOpen}
          toggleDialog={setDealerDialogOpen}
          initialValues={initialValues}
          onSubmit={onSubmitDealerClick}
        />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          toggleDialog={setConfirmationDialogOpen}
          initialValues={initialValues}
          onSubmit={onDeleteDealerConfirm}
        />
      </GridContainer>
    </Fade>
  );
}
