import axios from "axios";
import AuthService from "./AuthService";

export const BASE_URL = process.env.NODE_ENV === "development"
  ? "http://localhost:5000"
  : "https://opanky.herokuapp.com";

export default class AxiosFactory {
  axios = null;

  constructor(isAdmin) {
    let headers = {};

    let token = AuthService.getToken();
    headers = { Authorization: `Bearer ${token}` };

    this.axios = axios.create({
      baseURL: BASE_URL,
      headers
    });

    this.axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (!error.response) {
          throw error;
        }
        if (error.response.status === 401) {
          if (isAdmin) {
            AuthService.logout();
            window.location.href = "/admin/login";
          }
        }

        throw error;
      }
    );
  }
}
