import AxiosFactory from "./AxiosFactory";
import ApiPath from "../constants/ApiPath";

export default class AuthService {
  static login(data) {
    return new AxiosFactory().axios({
      method: "POST",
      url: ApiPath.user.login,
      data
    });
  }

  static logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("expires");
  }

  static loggedIn() {
    const token = this.getToken();
    // const expires = this.getExpires();
    const isLoggedIn = !!token; /*&& !this.isTokenExpired(expires);*/

    // return true;
    return isLoggedIn;
  }

  static getToken() {
    let token = localStorage.getItem("token");
    return token !== null ? token : "";
  }

  static setToken(token) {
    localStorage.setItem("token", token);
  }

  static isTokenExpired(expires) {
    try {
      return expires < Date.now();
    } catch (err) {
      return false;
    }
  }
}
