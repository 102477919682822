import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Fade, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
//@material-ui/icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DealersIcon from "@material-ui/icons/SupervisedUserCircle";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
// core components

import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import CardFooter from "../components/Card/CardFooter.js";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Table from "../components/Table/Table.js";
import Muted from "../components/Typography/Muted";

//services
import LocationService from "../../../services/LocationService";
import PlanService from "../../../services/PlanService";
import DealerService from "../../../services/DealerService";

//dialogs
import LocationDialog from "./LocationDialog";
import ConfirmationDialog from "./ConfirmationDialog";
import PlanDialog from "./PlanDialog";

import { successColor } from "../assets/jss/material-dashboard-react";
import DealersDialog from "./DealersDialog";

// import "../assets/css/material-dashboard-react.css";

const styles = {
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: '"Comforta", sans-serif',
    marginBottom: "3px",
    textDecoration: "none"
  },
  cardwrapper: {
    "&:hover": {
      "& $locationCardAction": {
        visibility: "visible"
      }
    }
  },
  locationTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  locationTitleText: {
    margin: 2
  },
  locationCardAction: {
    visibility: "hidden"
  },
  dealersIcon: {
    fill: successColor[1]
  },
  dealersTitle: {
    margin: 0,
    marginLeft: 5
  }
};

const useStyles = makeStyles(styles);

export const EntityTypes = {
  location: "location",
  plan: "plan",
  dealer: "dealer"
};

export default function LocationPlans() {
  const classes = useStyles();
  const [fade, setFade] = useState(false);
  const [locations, setLocations] = useState([]);
  const [dealers, setDealers] = useState([]);

  //dialogs
  const [initialValues, setInitialValues] = useState(null);
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const [planDialogOpen, setPlanDialogOpen] = useState(false);
  const [dealersDialogOpen, setDealersDialogOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogType, setConfirmationDialogType] = useState("");

  useEffect(() => {
    fetchLocations();
    fetchDealers();
  }, []);

  const fetchLocations = async () => {
    try {
      const result = await LocationService.Get();
      if (result.data.length) {
        setLocations(result.data);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setFade(true);
    }
  };

  const fetchDealers = async () => {
    try {
      const result = await DealerService.Get();
      if (result.data.length) {
        setDealers(result.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const onDeleteConfirm = async values => {
    if (confirmationDialogType === "location") {
      await onDeleteLocationConfirm(values);
      return;
    }
    if (confirmationDialogType === "plan") {
      await onDeletePlanConfirm(values);
      return;
    }
    return;
  };

  // location methods
  const onEditLocationClick = (_id, name) => {
    setInitialValues({
      _id,
      name
    });
    setLocationDialogOpen(true);
  };

  const onAddLocationClick = () => {
    setLocationDialogOpen(true);
    setInitialValues(null);
  };

  const onDeleteLocationClick = _id => {
    setInitialValues({
      _id
    });
    setConfirmationDialogType(EntityTypes.location);
    setConfirmationDialogOpen(true);
  };

  const onDeleteLocationConfirm = async values => {
    try {
      await LocationService.Delete(values._id);
      const index = locations.findIndex(x => x._id === values._id);
      if (index !== -1) {
        const filteredLocations = locations.filter(x => x._id !== values._id);
        setLocations(filteredLocations);
      }
      setConfirmationDialogOpen(false);
      setInitialValues(null);
    } catch (err) {
      console.log(err.message);
    }
  };

  const onSubmitLocationClick = async values => {
    try {
      const result = values._id
        ? await LocationService.Update(values)
        : await LocationService.Add(values);
      if (!values._id) {
        setLocations([...locations, result.data]);
      } else {
        const index = locations.findIndex(x => x._id === values._id);
        if (index !== -1) {
          const locationsCopy = [...locations];
          locationsCopy[index].name = values.name;
          setLocations(locationsCopy);
        }
      }
      setLocationDialogOpen(false);
    } catch (err) {
      if (err && err.response && err.response.data) {
        return { name: err.response.data };
      }
    }
  };

  // plan methods
  const onEditPlanClick = plan => {
    setInitialValues(plan);
    setPlanDialogOpen(true);
  };

  const onAddPlanClick = locationId => {
    setPlanDialogOpen(true);
    setInitialValues({
      locationId
    });
  };

  const onDeletePlanClick = (_id, locationId) => {
    setInitialValues({
      _id,
      locationId
    });
    setConfirmationDialogType(EntityTypes.plan);
    setConfirmationDialogOpen(true);
  };

  const onDeletePlanConfirm = async values => {
    try {
      await PlanService.Delete(values._id);
      const locationIndex = locations.findIndex(
        x => x._id === values.locationId
      );
      if (locationIndex !== -1) {
        const locationsCopy = [...locations];
        const locationPlans = locationsCopy[locationIndex].plans;
        const filteredLocationPlans = locationPlans.filter(
          x => x._id !== values._id
        );
        locationsCopy[locationIndex].plans = filteredLocationPlans;
        setLocations(locationsCopy);
      }
      setConfirmationDialogOpen(false);
      setInitialValues(null);
    } catch (err) {
      console.log(err.message);
    }
  };

  const onSubmitPlanClick = async values => {
    try {
      const result = values._id
        ? await PlanService.Update(values)
        : await PlanService.Add(values);
      if (!values._id) {
        // add new item to list of plans of certain location
        const locationsCopy = [...locations];
        const index = locations.findIndex(x => x._id === values.locationId);
        if (index !== -1) {
          locationsCopy[index].plans.push(result.data);
          setLocations(locationsCopy);
        }
      } else {
        const locationsCopy = [...locations];
        const locationIndex = locations.findIndex(
          x => x._id === values.locationId
        );
        if (locationIndex !== -1) {
          const planIndex = locationsCopy[locationIndex].plans.findIndex(
            x => x._id === result.data._id
          );
          locationsCopy[locationIndex].plans[planIndex] = result.data;
          setLocations(locationsCopy);
        }
      }
      setPlanDialogOpen(false);
    } catch (err) {
      if (err && err.response && err.response.data) {
        return { title: err.response.data };
      }
    }
  };

  const onFavoriteClick = async (plan, locationId) => {
    try {
      const newPlan = {
        ...plan,
        favorite: !plan.favorite,
      };
      await PlanService.SetFavorite(newPlan);
      const locationsCopy = [...locations];
      const locationIndex = locations.findIndex(
        x => x._id === locationId
      );
      if (locationIndex !== -1) {
        locationsCopy[locationIndex].plans.forEach(p => {
          p.favorite = false;
          if (p._id === newPlan._id && newPlan.favorite) {
            p.favorite = true;
          }
        });
        setLocations(locationsCopy);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  // dealer methods
  const onEditDealersClick = (locationId, dealers) => {
    setInitialValues({
      locationId,
      dealers: dealers.map(x => x._id)
    });
    setDealersDialogOpen(true);
  };

  const onSubmitDealersClick = async values => {
    try {
      await LocationService.UpdateDealers(
        values.locationId,
        { dealers: values.dealers.map(x => x._id) },
      );
      const locationsCopy = [...locations];
      const locationIndex = locations.findIndex(
        x => x._id === values.locationId
      );
      if (locationIndex !== -1) {
        locationsCopy[locationIndex].dealers = values.dealers;
        setLocations(locationsCopy);
      }
      setDealersDialogOpen(false);
    } catch (err) {
      if (err && err.response && err.response.data) {
        return { title: err.response.data };
      }
      setDealersDialogOpen(false);
    }
  };

  return (
    <Fade in={fade}>
      <GridContainer justify="center">
        <GridItem xs={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <div className={classes.headerContainer}>
                <Typography component="h4" className={classes.cardTitleWhite}>Регіони та плани</Typography>
                <IconButton aria-label="Додати" onClick={onAddLocationClick}>
                  <AddIcon style={{ color: "#fff" }} />
                </IconButton>
              </div>
            </CardHeader>
            <CardBody>
              <div style={{ marginTop: 27 }}>
                <GridContainer justify="center">
                  {locations.map((item, index) => {
                    if (!item.plans) {
                      item.plans = [];
                    }
                    if (!item.dealers) {
                      item.dealers = [];
                    }
                    return (
                      <GridItem key={index} xs={12}>
                        <div className={classes.cardwrapper}>
                          <Card>
                            <CardHeader style={{ marginBottom: -30 }}>
                              <GridContainer>
                                <GridItem xs={8}>
                                  <GridContainer alignItems="center">
                                    <GridItem>
                                      <Typography component="h4" className={classes.locationTitleText}>
                                        {item.name}
                                      </Typography>
                                    </GridItem>
                                    <GridItem>
                                      <IconButton
                                        onClick={() =>
                                          onEditLocationClick(
                                            item._id,
                                            item.name
                                          )
                                        }
                                        aria-label="Редагувати"
                                        className={classes.locationCardAction}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                                <GridItem xs={4} style={{ textAlign: "end" }}>
                                  <GridContainer
                                    direction="row"
                                    justify="flex-end"
                                  >
                                    <GridItem xs={6}>
                                      <IconButton
                                        onClick={() =>
                                          onDeleteLocationClick(item._id)
                                        }
                                        aria-label="Видалити"
                                        className={classes.locationCardAction}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                            </CardHeader>
                            <CardBody>
                              <GridContainer key={index}>
                                <GridItem xs={12}>
                                  <Table
                                    tableHeaderColor="primary"
                                    tableHead={[
                                      "Назва",
                                      "Ціна / грн",
                                      "Вхід МБІТ / сек",
                                      "Вихід МБІТ / сек",
                                      "",
                                      <IconButton
                                        key={index}
                                        aria-label="Додати"
                                        onClick={() => onAddPlanClick(item._id)}
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    ]}
                                    lastColIsAction
                                    tableData={item.plans.map(plan => {
                                      return [
                                        plan.title,
                                        plan.price,
                                        plan.speedIn,
                                        plan.speedOut,
                                        <IconButton
                                          onClick={() =>
                                            onFavoriteClick(plan, item._id)
                                          }
                                          aria-label="Улюблений"
                                        >
                                          {plan.favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                        </IconButton>,
                                        <GridContainer
                                          key={index}
                                          justify="flex-end"
                                        >
                                          <GridItem>
                                            <IconButton
                                              onClick={() =>
                                                onEditPlanClick(plan)
                                              }
                                              aria-label="Редагувати"
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </GridItem>
                                          <GridItem>
                                            <IconButton
                                              onClick={() =>
                                                onDeletePlanClick(
                                                  plan._id,
                                                  item._id
                                                )
                                              }
                                              aria-label="Видалити"
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </GridItem>
                                        </GridContainer>
                                      ];
                                    })}
                                  />
                                </GridItem>
                              </GridContainer>
                              {item.plans.length === 0 && (
                                <Muted
                                  style={{ textAlign: "center", marginTop: 10 }}
                                >
                                  {"Немає жодного плану"}
                                </Muted>
                              )}
                              {item.plans.length > 0 && (
                                <div style={{ marginTop: 27 }}>
                                  <GridContainer>
                                    <GridItem
                                      xs={6}
                                      style={{
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                    >
                                      <DealersIcon
                                        className={classes.dealersIcon}
                                      />
                                      <Typography component="h5" className={classes.dealersTitle}>
                                        {"Диллери"}
                                      </Typography>
                                    </GridItem>
                                    <GridItem
                                      xs={6}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end"
                                      }}
                                    >
                                      <IconButton
                                        onClick={() =>
                                          onEditDealersClick(
                                            item._id,
                                            item.dealers
                                          )
                                        }
                                        aria-label="Редагувати"
                                        className={classes.locationCardAction}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </GridItem>

                                    <GridItem xs={12}>
                                      {item.dealers.length === 0 ? (
                                        <Muted
                                          style={{
                                            marginTop: 10
                                          }}
                                        >
                                          {"Немає жодного диллера"}
                                        </Muted>
                                      ) : (
                                          <Muted
                                            style={{
                                              marginTop: 10
                                            }}
                                          >
                                            {item.dealers
                                              .map(x => x.name)
                                              .join(", ")}
                                          </Muted>
                                        )}
                                    </GridItem>
                                  </GridContainer>
                                </div>
                              )}
                            </CardBody>
                          </Card>
                        </div>
                      </GridItem>
                    );
                  })}
                  {locations.length === 0 && (
                    <Muted style={{ textAlign: "center", marginTop: 10 }}>
                      {"Немає жодного регіону"}
                    </Muted>
                  )}
                </GridContainer>
              </div>
            </CardBody>
            <CardFooter></CardFooter>
          </Card>
        </GridItem>
        <LocationDialog
          open={locationDialogOpen}
          toggleDialog={setLocationDialogOpen}
          initialValues={initialValues}
          onSubmit={onSubmitLocationClick}
        />
        <PlanDialog
          open={planDialogOpen}
          toggleDialog={setPlanDialogOpen}
          initialValues={initialValues}
          onSubmit={onSubmitPlanClick}
        />
        <DealersDialog
          open={dealersDialogOpen}
          toggleDialog={setDealersDialogOpen}
          initialValues={initialValues}
          options={dealers}
          onSubmit={onSubmitDealersClick}
        />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          toggleDialog={setConfirmationDialogOpen}
          initialValues={initialValues}
          onSubmit={onDeleteConfirm}
          type={confirmationDialogType}
        />
      </GridContainer>
    </Fade>
  );
}

LocationPlans.propTypes = {
  history: PropTypes.any
};
