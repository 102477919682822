import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import Home from "./containers/Main";
import Admin from "./containers/Admin";

export default function App() {
    return (
        <Router>
            <Switch>
                <Route path="/admin" component={Admin} />
                <Route path="/" component={Home} />
            </Switch>
        </Router>
    );
}