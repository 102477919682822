import React from "react";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Router from "./Router";
import { SnackbarProvider } from "./components/Notification";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#8b91e0',
      main: '#6f76d9',
      dark: '#4d5297',
      contrastText: '#fff',
    },
    secondary: {
      light: '#93deab',
      main: '#78D697',
      dark: '#8fd39e',
      contrastText: '#fff',
    },
    text: {
      primary: "#50556c",
      secondary: "#828a99",
    },
  },
  typography: {
    fontFamily: [
      'Comfortaa',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiPaper: {
      root: {
        borderRadius: 6,
      },
      outlined: {
        borderRadius: 6,
      },
      rounded: {
        borderRadius: 6,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 5,
        padding: "10px 34px",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
      },
      outlined: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
      },
      label: {
        textTransform: "none",
        fontWeight: 400,
      },
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
}