import * as React from "react";
import NumberFormat from "react-number-format";

const CustomNumberFormat = props => {
    const { inputRef, onChange, ...other } = props;
    const format = "+# (###) ###-#####";
    return (
        <NumberFormat
            {...other}
            format={format}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
        />
    );
};

export default CustomNumberFormat;