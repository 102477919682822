import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/customInputStyle";

const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
  const classes = useStyles();
  const {
    children,
    labelText,
    value,
    onChange,
    name,
    multiple,
    renderValue
  } = props;
  return (
    <FormControl className={classes.formControl} variant="outlined" fullWidth>
      <InputLabel htmlFor={name}>{labelText}</InputLabel>
      <Select
        multiple={multiple}
        onChange={onChange}
        value={value}
        renderValue={renderValue}
        inputProps={{
          name: name,
          id: name
        }}
        MenuProps={{
          classes: {
            paper: classes.selectMenu
          }
        }}
        fullWidth
        input={
          <Input
            classes={{
              underline: classes.underline
            }}
            name={name}
            id={name}
          />
        }
      >
        {children}
      </Select>
    </FormControl>
  );
}

CustomSelect.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  multiple: PropTypes.bool,
  value: PropTypes.any,
  renderValue: PropTypes.any
};
