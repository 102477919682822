import AxiosFactory from "./AxiosFactory";
import ApiPath from "../constants/ApiPath";

export default class DealerService {
  static Get() {
    return new AxiosFactory(true).axios({
      method: "GET",
      url: ApiPath.dealers.get
    });
  }
  static Add(data) {
    return new AxiosFactory(true).axios({
      method: "POST",
      url: `${ApiPath.dealers.add}`,
      data
    });
  }

  static Update(data) {
    return new AxiosFactory(true).axios({
      method: "PUT",
      url: `${ApiPath.dealers.update}/${data._id}`,
      data
    });
  }

  static Delete(id) {
    return new AxiosFactory(true).axios({
      method: "DELETE",
      url: `${ApiPath.dealers.delete}/${id}`
    });
  }
}
