import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import DealerIcon from "@material-ui/icons/AccountCircle";

// react-final-form
import { Form, Field } from "react-final-form";
import FormValidators from "../../../helpers/form-validators";

// core components
import Dialog from "../components/Dialog";
import Button from "../components/CustomButtons/Button";
import TextFieldControl from "../components/FormElements/TextFieldControl";

export default function DealerDialog(props) {
  const { open, toggleDialog, initialValues, onSubmit } = props;
  return (
    <Dialog
      open={open}
      onClose={() => toggleDialog(false)}
      icon={DealerIcon}
      title={""}
      main={
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent style={{ overflowY: "unset" }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Field
                      component={TextFieldControl}
                      name="name"
                      type={"text"}
                      validate={FormValidators.composeValidators(
                        FormValidators.required()
                      )}
                      label="Ім'я"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextFieldControl}
                      withPhoneMask
                      name="phoneNumber"
                      type={"text"}
                      validate={FormValidators.composeValidators(
                        FormValidators.required(),
                        FormValidators.isPhoneNumber()
                      )}
                      label="Номер телефону"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextFieldControl}
                      name="email"
                      type={"text"}
                      label="Email"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => toggleDialog(false)} color="default">
                  {"Відміна"}
                </Button>
                <Button type="submit" color="rose">
                  {initialValues ? "Зберегти" : "Додати"}
                </Button>
              </DialogActions>
            </form>
          )}
        />
      }
    ></Dialog>
  );
}

DealerDialog.propTypes = {
  open: PropTypes.bool,
  toggleDialog: PropTypes.func,
  initialValues: PropTypes.any,
  onSubmit: PropTypes.func
};
