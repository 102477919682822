import * as React from "react";
import classnames from "classnames";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import { makeStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// import Favorite from "@material-ui/icons/FavoriteBorder";

import { theme } from "../App";

import 'react-circular-progressbar/dist/styles.css';

const useStyles = makeStyles(theme => ({
    rootPro: {
        marginTop: -15,
    },
    card: {
        backgroundColor: "#fff",
        border: 0,
        borderRadius: 4,
        padding: 30,
        margin: 10,
        boxShadow: "0 0 20px rgba(141,149,166,0.13)",
        transition: "all 0.4s ease-out",
    },
    cardPro: {
        paddingTop: 45,
    },
    cardHeader: {
    },
    cardHeaderTitle: {
        [theme.breakpoints.down("sm")]: {
            maxWidth: "50%",
            margin: "0 auto",
        },
    },
    cardHeaderSubheader: {
        fontWeight: 600,
        marginTop: theme.spacing(7),
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        marginTop: theme.spacing(2),
    },
    cardActions: {
        justifyContent: "center",
    },
}));

const circularMax = 70;

const Tier = ({ item, maxSpeed, onConnect }) => {
    const classes = useStyles();

    const isFavorite = item.favorite;

    const circularColor = isFavorite ? theme.palette.secondary.light : theme.palette.primary.light;
    const circularStyles = buildStyles({
        rotation: -0.35,
        textColor: circularColor,
        pathColor: circularColor,
        textSize: 10,
    });

    const calculateProgressValue = () => {
        return item.speedIn * circularMax / maxSpeed;
    };

    return (
        <Grid item key={item.title} xs={11} sm={6} md={4} className={isFavorite ? classes.rootPro : ""}>
            <Card
                classes={{
                    root: classnames(classes.card, isFavorite ? classes.cardPro : "")
                }}
                variant="outlined"
                raised={false}
            >
                <CardHeader
                    title={
                        <CircularProgressbar
                            value={calculateProgressValue()}
                            styles={circularStyles}
                            text={`${item.speedIn} Мбіт/с`}
                            className={classes.circularProgressbar}
                        />
                    }
                    subheader={
                        <Typography
                            className={classes.cardHeaderSubheader}
                            align="center"
                            variant="h5"
                        >
                            {item.title}
                        </Typography>
                    }
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                    classes={{
                        title: classes.cardHeaderTitle,
                        subheader: classes.cardHeaderSubheader,
                    }}
                />
                <CardContent>
                    <div className={classes.cardPricing}>
                        <Typography component="h2" variant="h4" color="textPrimary">
                            {item.price}{" "}{"грн"}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                            {"/міс"}
                        </Typography>
                    </div>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <Button
                        onClick={onConnect}
                        variant="contained" color={isFavorite ? "secondary" : "primary"}
                    >
                        {"Підключитись"}
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default Tier;