import AxiosFactory from "./AxiosFactory";
import ApiPath from "../constants/ApiPath";

export default class LocationService {
  static Add(data) {
    return new AxiosFactory(true).axios({
      method: "POST",
      url: `${ApiPath.plans.add}`,
      data
    });
  }

  static Update(data) {
    return new AxiosFactory(true).axios({
      method: "PUT",
      url: `${ApiPath.plans.update}/${data._id}`,
      data
    });
  }

  static SetFavorite(data) {
    return new AxiosFactory(true).axios({
      method: "PUT",
      url: `${ApiPath.plans.setFavorite}/${data._id}`,
      data,
    });
  }

  static Delete(id) {
    return new AxiosFactory(true).axios({
      method: "DELETE",
      url: `${ApiPath.plans.delete}/${id}`
    });
  }
}
