import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import IconButton from "@material-ui/core/IconButton";
import Divider from '@material-ui/core/Divider';

//@material-ui/icons
import PhoneIcon from "@material-ui/icons/Phone";

// images
import privatbank from "../../assets/icons/privatbank-icon.png";
import privatStep1 from "../../assets/instructions/privat-1.png";
import privatStep2 from "../../assets/instructions/privat-2.png";
import privatStep3 from "../../assets/instructions/privat-3.png";
import privatStep4 from "../../assets/instructions/privat-4.png";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 50,
    },
    tabsRoot: {
        width: "75%",
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            width: "95%",
        },
    },
    tabPanel: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    swipeableViews: {
        width: "75%",
        [theme.breakpoints.down("sm")]: {
            width: "95%",
        },
    },
    requisites: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    requisitesBox: {
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    phoneIconButton: {
        marginLeft: theme.spacing(),
    },
    divider: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(2),
    },
    atmBox: {
        // marginBottom: theme.spacing(2),
    },
    privat24Box: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& p": {
            width: "33.3%",
        },
        "& img": {
            width: "66.6%",
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            "& p": {
                width: "100%",
                order: 1,
            },
            "& img": {
                width: "100%",
                order: 2,
            },
        },
    },
    privatImage: {
        width: "100%",
        height: "100%",
        borderRadius: 6,
        border: "1px solid #cccccc",
    },
}));


function TabPanel(props) {
    const classes = useStyles();
    const { children } = props;

    return (
        <Container maxWidth="lg" className={classes.tabPanel}>
            {children}
        </Container>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function Payments() {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    function handleChangeIndex(index) {
        setValue(index);
    }

    return (
        <div id="payments" className={classes.root}>
            <Typography component="p" variant="h4" align="center" color="textPrimary" gutterBottom>
                {"Оплата"}
            </Typography>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
                classes={{ root: classes.tabsRoot }}
            >
                <Tab label={"Термінал"} {...a11yProps(1)} />
                <Tab label={"Privat 24"} {...a11yProps(0)} />
                <Tab label={"Банк"} {...a11yProps(2)} />
            </Tabs>
            <SwipeableViews
                className={classes.swipeableViews}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                animateHeight
            >
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Box display="flex" flexDirection="column">
                        <Box height={32} />
                        <Box className={classes.atmBox}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <img
                                    src={privatbank}
                                    alt="privatbank"
                                    style={{
                                        width: 80,
                                        height: 15,
                                        marginRight: 20,
                                    }}
                                />
                                <Typography>Термінали ПриватБанку</Typography>
                            </Box>
                            <Box height={12} />
                            <Typography>
                                {"В пошуку введіть назву компанії (Опаньки.нет)."}{" "}
                                {"Швидкий та зручний у використанні сервіс - гроші потрапляють на ваш рахунок миттєво."}{" "}
                                {"Зазвичай термінали розташовані на території великих маркетів або в звичайних магазинах."}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography>
                                {"Для поповнення в інших терміналах самообслуговування вам потрібно дізнатись реквізити."}
                            </Typography>
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Box display="flex" flexDirection="column">
                        <Box height={32} />
                        <Box className={classes.privat24Box}>
                            <Typography>
                                {`1. В основному вікні privat24 обираємо вкладку
                                Сервіси, Телекомунікації Далі в полі для пошуку
                                вводимо назву компанії ОПАНЬКИ.НЕТ, з отриманого
                                переліку обираємо ОПАНЬКИ.НЕТ `}
                            </Typography>
                            <Box width={12} />
                            <img src={privatStep1} className={classes.privatImage} alt="s1" />
                        </Box>
                        <Box height={24} />
                        <Box className={classes.privat24Box}>
                            <img src={privatStep2} className={classes.privatImage} alt="s2" />
                            <Box width={12} />
                            <Typography>
                                {`2. В наступному полі вводимо Ваш код (UID) який можна
                                дізнатись у нашого оператора і натискаємо далі.`}
                            </Typography>
                        </Box>
                        <Box height={24} />
                        <Box className={classes.privat24Box}>
                            <Typography>
                                {`3. Вводимо суму оплати згідно вашого тарифного плану і натискаємо Додати платіж у кошик`}
                            </Typography>
                            <Box width={12} />
                            <img src={privatStep3} className={classes.privatImage} alt="s3" />
                        </Box>
                        <Box height={24} />

                        <Box className={classes.privat24Box}>
                            <img src={privatStep4} className={classes.privatImage} alt="s4" />
                            <Box width={12} />
                            <Typography>
                                {`4. Натискаємо підтвердити.`}
                            </Typography>
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <Box>
                        <Box height={12} />
                        <Typography>
                            {"Поповнення рахунку може здійснитись у будь–якому зручному"}{" "}
                            {"для вас відділенні банку. Для цього вам потрібно дізнатись реквізити"} {" "}
                            {"та при оплаті вказати населений пункт та ПІБ."}
                        </Typography>
                    </Box>
                </TabPanel>
            </SwipeableViews>

            <Container maxWidth="lg" className={classes.requisites}>
                <Divider className={classes.divider} />
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.requisitesBox}
                >
                    <Typography align="center">Дізнатись реквізити можна за номерами:</Typography>
                    <Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography>+380 (96) 220 32 70 (Kyivstar)</Typography>
                            <IconButton
                                href="tel:+380 (96) 220 32 70"
                                className={classes.phoneIconButton}
                                size="small"
                            >
                                <PhoneIcon color="secondary" className={classes.icon} />
                            </IconButton>
                        </Box>
                        <Box style={{ height: 20 }}></Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography>+380 (95) 220 32 70 (Vodafone)</Typography>
                            <IconButton
                                href="tel:+380 (95) 220 32 70"
                                className={classes.phoneIconButton}
                                size="small"
                            >
                                <PhoneIcon color="secondary" className={classes.icon} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}