import React, { } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        // height: "100vh",
        display: "flex",
        alignItems: "center",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: 130,
    },
    commas: {
        fontSize: 90,
        color: theme.palette.secondary.main,
    },
}));

const Quotes = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="lg" component="main" className={classes.container}>
                <Typography variant="h6" align="center" component="p" gutterBottom className={classes.commas}>
                    <span>&#44;</span>
                    <span>&#44;</span>
                </Typography>
                <Container maxWidth="md">
                    <Typography component="p" align="center" color="textSecondary">
                        {"Шукаєте надійного інтернет провайдера у вашому регіоні?"}
                    </Typography>
                    <Typography component="p" align="center" color="textSecondary">
                        {"Новий погляд на  системне підключення та швидкий Інтернет надає"}
                    </Typography>
                    <Typography component="p" align="center" color="textSecondary">
                        {"можливість просто та швидко вирішувати будь-які завдання."}
                    </Typography>
                    <Typography component="p" align="center" color="textSecondary" gutterBottom>
                        {"Спілкуйся, Працюй, Навчайся та Розважайся."}
                    </Typography>
                    <Typography component="p" align="center" color="textSecondary">
                        {"В той час як інші інтернет провайдери обіцяють, Опаньки виконує свої обіцянки. "}
                    </Typography>
                    <Typography component="p" align="center" color="textSecondary">
                        {`Переконайтесь в цьому самі, ставши нашим абонентом.`}
                    </Typography>
                </Container>
            </Container>
        </div >
    );
};

export default Quotes;