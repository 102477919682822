/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// core components
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import CardFooter from "../components/Card/CardFooter.js";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Button from "../components/CustomButtons/Button.js";
import TextFieldControl from "../components/FormElements/TextFieldControl";

//@material-ui/icons
import EmailIcon from "@material-ui/icons/Email";
import PasswordIcon from "@material-ui/icons/Lock";

import { roseColor } from "../assets/jss/material-dashboard-react.js";
import { Form, Field } from "react-final-form";
import FormValidators from "../../../helpers/form-validators";
import { Fade } from "@material-ui/core";
import AuthService from "../../../services/AuthService";

const styles = {
    typo: {
        paddingLeft: "25%",
        marginBottom: "40px",
        position: "relative"
    },
    note: {
        fontFamily: '"Comforta", sans-serif',
        bottom: "10px",
        color: "#c0c1c2",
        display: "block",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "13px",
        left: "0",
        marginLeft: "20px",
        position: "absolute",
        width: "260px"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "600",
        fontFamily: '"Comforta", sans-serif',
        marginBottom: "3px",
        textDecoration: "none",
        textAlign: "center"
    },
    icon: {
        color: roseColor[0]
    }
};

const useStyles = makeStyles(styles);

export default function Login(props) {
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [initialValues, setInitialValues] = useState({
        email: "",
        password: ""
    });
    const [fade, setFade] = useState(false);
    useEffect(() => {
        if (AuthService.loggedIn()) {
            props.history.push("/admin/locationPlans");
        }
        setTimeout(() => {
            setFade(true);
        }, 100);
        // eslint-disable-next-line
    }, []);

    const login = async values => {
        try {
            const res = await AuthService.login({
                email: values.email,
                password: values.password
            });
            AuthService.setToken(res.data.token);
            props.history.push("/admin/locationPlans");
        } catch (err) {
            console.log(err.response);
            alert("Перевірте дані");
        }
    };
    return (
        <Form
            onSubmit={login}
            initialValues={initialValues}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Fade in={fade}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={8} md={3}>
                                <Card>
                                    <CardHeader color="primary">
                                        <h4 className={classes.cardTitleWhite}>Opanky</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ marginTop: 27 }}>
                                            <GridContainer>
                                                <GridItem xs={12}>
                                                    <GridContainer>
                                                        <GridItem xs={12}>
                                                            <Field
                                                                component={TextFieldControl}
                                                                name="email"
                                                                type={"email"}
                                                                validate={FormValidators.composeValidators(
                                                                    FormValidators.required(),
                                                                    FormValidators.isEmail
                                                                )}
                                                                label="Email"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <EmailIcon color="action" />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </GridItem>
                                                        <GridItem xs={12}>
                                                            <Field
                                                                component={TextFieldControl}
                                                                name="password"
                                                                type={"password"}
                                                                validate={FormValidators.required()}
                                                                label="Пароль"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <PasswordIcon color="action" />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    </CardBody>
                                    <CardFooter login>
                                        <Button color="primary" type="submit" variant="outlined">
                                            {"Ввійти"}
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </Fade>
                </form>
            )}
        />
    );
}
