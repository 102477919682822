import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Login from "./Login";
import LocationPlans from "./LocationPlans";
import Dealers from "./Dealers";
import Logout from "./Logout";

import Sidebar from "./components/Sidebar/Sidebar";
// import "./assets/css/material-dashboard-react.css";

// const AdminAuth = () => {
//     return (
//         <div>
//             <Sidebar />
//             <Router>
//                 <Switch>
//                     <Route path="/" component={LocationPlans} />
//                     <Route path="/admin/dealers" component={Dealers} />

//                     <Redirect from="/" to="/admin/dealers" />
//                 </Switch>
//             </Router>
//         </div>
//     );
// };

export default function Admin({ ...rest }) {
    return (
        <>
            <Router>
                <Sidebar />
                <Switch>
                    <Route path="/admin/locationPlans" component={LocationPlans} />
                    <Route path="/admin/dealers" component={Dealers} />

                    <Route path="/admin/login" component={Login} />
                    <Route path="/admin/logout" component={Logout} />

                    <Redirect from="/admin" to="/admin/login" />
                </Switch>
            </Router>
        </>
    );
}