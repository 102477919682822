import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import Hero from './Hero';
import Tiers from './Tiers';
import Benefits from './Benefits';
import Quotes from './Quotes';
import ContactUs from './ContactUs';

import LocationService from "../../services/LocationService";
import Payments from './Payments';

const useStyles = makeStyles(theme => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        marginTop: theme.spacing(4),
    },
    toolbar: {
        flexWrap: 'wrap',
        backgroundColor: "#fff",
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(12, 0, 6),
        textAlign: "center",
        marginBottom: theme.spacing(55),
    },
    tiersContainer: {
        marginBottom: theme.spacing(20),
    },
    benefitsContainer: {
        marginBottom: theme.spacing(20),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));


export default function Main() {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const result = await LocationService.Get();
            if (result.data) {
                setLocations(result.data);
            }
        }
        catch{ }
        finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <ScrollAnimation animateOnce animateIn="fadeIn">
                <Hero loading={loading} />
            </ScrollAnimation>
            {!loading && (
                <>
                    <Tiers locations={locations} />
                    {/* <ScrollAnimation animateOnce animateIn="fadeIn"> */}
                    <Benefits />
                    {/* </ScrollAnimation > */}
                    <ScrollAnimation animateOnce animateIn="fadeIn">
                        <Quotes />
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce animateIn="fadeIn">
                        <Payments />
                    </ScrollAnimation>
                    <ScrollAnimation animateOnce animateIn="fadeIn">
                        <ContactUs />
                    </ScrollAnimation>
                </>
            )}


            {/* Footer */}
            {/* <Container maxWidth="md" component="footer" className={classes.footer}>
                <Grid container spacing={4} justify="space-evenly">
                    {footers.map(footer => (
                        <Grid item xs={6} sm={3} key={footer.title}>
                            <Typography variant="h6" color="textPrimary" gutterBottom>
                                {footer.title}
                            </Typography>
                            <ul>
                                {footer.description.map(item => (
                                    <li key={item}>
                                        <Link href="#" variant="subtitle1" color="textSecondary">
                                            {item}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container> */}
            {/* End footer */}
        </div>
    );
}