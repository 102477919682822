import React, { useState, useEffect } from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

import BackgroundImage from "../../assets/background.svg";

import Tier from '../../components/Tier';
import { useSnackbar } from '../../components/Notification';

import NewConnectionDialog from '../../components/NewConnectionDoalog';
import ConnectionService from '../../services/ConnectionService';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#F2F5F8",
        minHeight: "105vh",
        display: "flex",
        alignItems: "flex-start",
        paddingTop: 50,
        [theme.breakpoints.down("sm")]: {
            height: "100%",
        }
    },
    tiersContainer: {
        // height: 434,
    },
}));

const Tiers = ({ locations }) => {
    const classes = useStyles();

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [open, setOpen] = React.useState(false);

    const snackbar = useSnackbar();

    useEffect(() => {
        setSelectedLocation(locations[0]);
    }, [locations]);

    const handleClickOpen = (plan) => {
        setSelectedPlan(plan);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (values) => {
        setOpen(false);
        values = {
            ...values,
            locationId: selectedLocation._id,
            planId: selectedPlan._id,
        };
        try {
            const res = await ConnectionService.Add(values);
            const message = res.data.message;
            if (!message) {
                console.error("New Connection request was not sent.", res);
            } else {
                snackbar.showMessage(message);
            }
        } catch (err) {
            console.error("New Connection request was not sent.", err.message);
        }
    };

    if (locations.length === 0) {
        return null;
    }

    const maxSpeed = selectedLocation ? Math.max(...selectedLocation.plans.map(x => x.speedIn)) : 0;

    return (
        <div id="tiers" className={classes.root}>
            <Container maxWidth="md" component="main" >
                {/* <Typography component="p" variant="h3" align="center" color="textPrimary">
                    {"Найбільш зручні та різноманітні тарифні плани"}
                </Typography> */}
                <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                    style={{ marginBottom: 50, }}
                >
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            autoComplete
                            options={locations}
                            getOptionLabel={option => option.name}
                            blurOnSelect
                            defaultValue={locations.length > 0 ? locations[0] : null}
                            //disable if no plans
                            // getOptionDisabled={option => option === timeSlots[0] || option === timeSlots[2]}
                            renderInput={params => <TextField
                                {...params}
                                label="Виберіть регіон"
                                variant="outlined"
                            />
                            }
                            noOptionsText="Ми нічого не знайшли..."
                            onChange={(e, val) => { setSelectedLocation(val) }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={0} alignItems="flex-start" justify="center" className={classes.tiersContainer}>
                    {selectedLocation && selectedLocation.plans
                        .sort((a, b) => a.price - b.price)
                        .map((tier, idx) =>
                            <Tier
                                key={idx}
                                item={tier}
                                maxSpeed={maxSpeed}
                                onConnect={() => handleClickOpen(tier)}
                            />
                        )
                    }
                    {!selectedLocation && (
                        <Typography>Виберіть локацію</Typography>
                    )}
                </Grid>
            </Container>
            <NewConnectionDialog open={open} handleClose={handleClose} handleSubmit={handleSubmit} />
        </div>
    );
};

export default Tiers;